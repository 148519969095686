import './App.css'

import turboWebsLogo from './Assets/Images/turbowebs_logo.png'

import '@fortawesome/fontawesome-free/css/all.css'
import PhoneIcon from '@material-ui/icons/Phone'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import LanguageIcon from '@material-ui/icons/Language'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'

function App() {
  return (
    <div className="container">
      <div className="card">
        <div className="card-title">
          <div className="card-title-info">
            <h1>MOHAMED IBRAHIM</h1>
            <h3>Founder and Business Developer</h3>
          </div>
          <div className="card-title-image">
            <img src={turboWebsLogo} alt="turboWebs-logo" />
          </div>
        </div>

        <div className="card-info">
          <div className="card-info-left">
            <div className="mobile">
              <div className="phone-icon">
                <PhoneIcon />
              </div>
              <div className="mobile-info">
                <div className="personal-mobile">
                  <div className="personal-mobile-info-lb">
                    <div className="region">Mobile</div>
                    <a id="personal_link" href="tel:+96170474757">
                      +961 70 47 47 57
                    </a>
                  </div>
                </div>
                <div className="office-mobile">
                  <div className="personal-mobile-info">
                    <div className="region">Lebanon office</div>
                    <a id="office_link" href="tel:+9611957673">
                      +961 1 95 76 73
                    </a>
                  </div>
                </div>

                <div className="uk-mobile">
                  <div className="personal-mobile-info">
                    <div className="region">UK</div>
                    <a id="uk_link" href="tel:+447498998688">
                      +4474 98 99 86 88
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="email">
              <MailOutlineIcon />
              <a id="email_link" href="mailto:mohamed@turbowbs.com">
                mohamed@turbowbs.com
              </a>
            </div>

            <div className="website">
              <LanguageIcon />
              <a id="website_link" href="https://www.turbowbs.com">
                www.turbowbs.com
              </a>
            </div>

            <div className="address">
              <LocationOnIcon />
              <p>
                Level 2 Louis Vuitton Building Beirut Souks<br></br>Allenby
                Street, Downtown Beirut<br></br>Beirut Lebanon
              </p>
            </div>
          </div>
          <div className="social-download">
            <div className="social">
              <div className="facebook">
                <a id="fb" href="https://m.facebook.com/Turbowebslebanon/">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </div>
              <div className="instagram">
                <a id="insta" href="https://instagram.com/turbowebs">
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div className="linkedin">
                <a
                  id="linkedin"
                  href="https://www.linkedin.com/in/mohamed-ibrahim-16717960"
                >
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
              <div className="twitter">
                <a id="twit" href="https://twitter.com/websturbo">
                  <i class="fab fa-twitter"></i>
                </a>
              </div>
            </div>
            <div className="download-div">
              <a
                id="download"
                href="/mohamed_ibrahim.vcf"
                download="mohamed_ibrahim.vcf"
                style={{
                  color: '#f9eae1',
                  cursor: 'pointer',
                }}
              >
                Download Contact
                <GetAppRoundedIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
